import React, { useContext, useEffect } from 'react';
import AppContext from "@src/context"
// Structure imports
import Seo from '@components/utility/SEO';
import { handleFBQ } from '@utils/FBQ';
import {
	KfHeroUtility
} from "@klickinc/kf-react-components";
import { Link } from "gatsby"

const SignUpConfirmation = () => {
	const ctx = useContext(AppContext);

	useEffect(() => {
		ctx.setPageClass('page--sign-up-confirmation');
		handleFBQ('CompleteRegistration');
	}, []);

	return (
		<>
			<div className='page--sign-up-confirmation'>
				<div className='container mt-8 xs:h-[21.875rem] md:mt-10 md:h-[43.75rem]'>
					<KfHeroUtility addedClass="heading heading-hero-blue pt-6 xs:text-[1.875rem] sm:w-[21.25rem] md:w-[33.125rem] md:text-6xl md:leading-[4.375rem]">thank you for signing up!</KfHeroUtility>
					<p className='orange_subHeader'>Please complete your subscription.</p>
					<p className='md:max-w-[31.25rem]'>An email has been sent to the email address you submitted. Please click the confirmation link in that email to complete your subscription.</p>
					<div className='text-center md:text-left'>
						<Link to='/' className='gohomeLink'>
							Return Home
						</Link>
					</div>
				</div>
				<div className="w-screen confirmation submitSection">
				</div>
			</div>
		</>
	);
};

export default SignUpConfirmation;

export function Head() {
	return (
		<Seo title='Thank You for Signing Up - EMFLAZA® (deflazacort)' addSocialMeta={false}>
			<meta name="robots" content="noindex" />
		</Seo>
	);
};
